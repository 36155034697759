<template>
  <v-img
    src="@/assets/bg_landing.png"
    lazy-src="@/assets/bg_landing_small.jpg"
    height="100%"
    width="100%"
    class="bg-cover"
  >
    <v-container
      fluid
      class="d-flex flex-column justify-center align-center"
      style="height: 100%"
    >
      <v-card class="mr-3 mt-10 justify-center" width="450px" color="white">
        <v-card-title class="d-flex" style="justify-content: space-between;">
          <v-img src="@/assets/title_logo.svg" max-width="270px" />
          <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-title class="mx-3 pb-0 d-flex align-center justify-center pt-0">
          <div style="font-family: 'Poppins-SemiBold'; color: #7024c4">
            Forgot Password?
          </div>
        </v-card-title>
        <v-card-text
          style="font-family: 'Poppins-SemiBold'; color: #7024c4"
          class="px-10 mt-8 pb-0"
        >
          Don’t worry! It happens. Please enter the email or phone number.
        </v-card-text>
        <v-card-title class="mx-3 mb-0 pb-0 pt-0">
          <v-text-field
            v-model="email_phone"
            label="Email or Phone"
            dense
            filled
            background-color="white"
            class="mt-3"
            @change="onChange"
            outlined
            style="width:100%"
            :rules="[rules.required]"
            :error-messages="errorMessage"
          />
        </v-card-title>
        <v-card-title
          class="mt-0 pt-0 d-flex align-center justify-center mb-5 px-7"
        >
          <v-btn
            dark
            color="#7024c4"
            width="100%"
            @click="forgotPasswordClicked"
            :loading="loading"
          >
            Send Code
          </v-btn>
        </v-card-title>
      </v-card>
    </v-container>
  </v-img>
</template>
<script>
import { mapActions } from "vuex";

export default {
  components: {},
  data() {
    return {
      email_phone: "",
      loading: false,
      errorMessage: null,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
        passwordMatch: () =>
          this.profile.password != this.profile.confirmPassword
            ? `Password doesn't match`
            : "",
      },
    };
  },
  methods: {
    ...mapActions({
      onForgotPassword: "auth/onForgotPassword",
    }),
    emailIsValid(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    phoneIsValid(number) {
      //eslint-disable-next-line
      return /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(number);
    },
    onClose() {
      this.$router.back();
    },
    forgotPasswordClicked() {
      if (this.loading) return;
      let email = "";
      let phone = "";
      if (this.emailIsValid(this.email_phone)) {
        email = this.email_phone;
      } else if (this.phoneIsValid(this.email_phone)) {
        phone = this.email_phone;
      } else {
        this.errorMessage = "Invalid Format";
        return;
      }
      const params = {
        email,
        phone,
      };
      this.loading = true;
      this.onForgotPassword(params)
        .then(() => {
          console.log(params);
          this.$router.push({ name: "verifycode", params });
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.data.message) {
            this.errorMessage = error.response.data.message;
          } else {
            this.errorMessage = error.message;
          }
        });
    },
    onChange() {
      this.errorMessage = null;
    },
  },
};
</script>
<style>
.welcome-title {
  font-family: "Poppins-Bold";
  font-size: 50px;
  text-shadow: 2px 2px 12px #0006;
  color: white;
  padding-left: 20px;
  padding-top: 50px;
  padding-bottom: 10px;
  display: block;
}
.welcome-description {
  font-family: "Poppins-Medium";
  font-size: 20px;
  padding-left: 20px;
  display: block;
}
.bg-blur {
  background: #fffc;
  position: absolute;
  height: 100%;
  width: 100%;
}
.bg-cover > .v-image__image {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
</style>
