<template>
  <v-img
    src="@/assets/bg_landing.png"
    lazy-src="@/assets/bg_landing_small.jpg"
    height="100%"
    width="100%"
    class="bg-cover"
  >
    <v-container
      fluid
      class="d-flex flex-column justify-center align-center"
      style="height: 100%"
    >
      <v-card class="mr-3 mt-10 justify-center" width="450px" color="white">
        <v-card-title class="d-flex" style="justify-content: space-between;">
          <v-img src="@/assets/title_logo.svg" max-width="270px" />
        </v-card-title>
        <v-card-title class="mx-3 pb-0 d-flex align-center justify-center pt-0">
          <div style="font-family: 'Poppins-SemiBold'; color: #7024c4">
            Verification code
          </div>
        </v-card-title>
        <v-card-text
          style="font-family: 'Poppins-SemiBold'; color: #7024c4"
          class="px-10 mt-8 pb-0"
        >
          HealthyCommunities sent otp to reset password.
        </v-card-text>
        <v-card-title class="mx-3 d-flex justify-center">
          <div>
            <CodeInput
              loading="false"
              class="input"
              v-model="verifycode"
              v-on:change="onChange"
              v-on:complete="onComplete"
            />
          </div>

          <v-alert type="error" v-if="errorMessage">
            {{ errorMessage }}
          </v-alert>
        </v-card-title>
        <v-card-title
          class="mt-0 pt-0 d-flex align-center justify-center px-7 mt-3"
        >
          <v-btn
            dark
            color="#7024c4"
            width="100%"
            @click="VerifyContinueClicked"
            :loading="loading"
          >
            CONTINUE
          </v-btn>
        </v-card-title>
        <v-card-title
          class="mt-0 pt-0 d-flex align-center justify-center mb-5 px-7"
        >
          <v-btn text color="#7024c4" width="100%" @click="onBack">
            back
          </v-btn>
        </v-card-title>
      </v-card>
    </v-container>
  </v-img>
</template>
<script>
import { mapActions } from "vuex";
import CodeInput from "vue-pincode-input";
export default {
  components: { CodeInput },
  data() {
    return {
      verifycode: "",
      loading: false,
      errorMessage: null,
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  methods: {
    ...mapActions({
      onSubmitVerificationCode: "auth/onSubmitVerificationCode",
    }),

    onChange(v) {
      console.log("onChange", v);
    },
    onComplete(v) {
      console.log("onComplete", v);
    },
    onBack() {
      this.$router.back();
    },
    VerifyContinueClicked() {
      if (this.loading || this.verifycode.length != 4) return;
      this.loading = true;
      this.onSubmitVerificationCode({
        ...this.$route.params,
        otp: this.verifycode,
      })
        .then(() => {
          this.$router.push({
            name: "resetpassword",
            params: { ...this.$route.params, otp: this.verifycode },
          });
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.data.message) {
            this.errorMessage = error.response.data.message;
          } else {
            this.errorMessage = error.message;
          }
        });
    },
  },
};
</script>
<style>
/* #app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 10px;
  margin-left: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
} */
</style>
<style>
.welcome-title {
  font-family: "Poppins-Bold";
  font-size: 50px;
  text-shadow: 2px 2px 12px #0006;
  color: white;
  padding-left: 20px;
  padding-top: 50px;
  padding-bottom: 10px;
  display: block;
}
.welcome-description {
  font-family: "Poppins-Medium";
  font-size: 20px;
  padding-left: 20px;
  display: block;
}
.bg-blur {
  background: #fffc;
  position: absolute;
  height: 100%;
  width: 100%;
}
.bg-cover > .v-image__image {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
</style>
